<template>
  <div class="slide-in space-y-6 h-full overflow-x-hidden">
    <div class="flex space-x-3 justify-end">
      <div>
        <button class="bg-primary button" @click="$router.push('add-enrollee')">
          <em class="fas fa-plus" />
          <span>Add Enrollee</span>
        </button>
      </div>
      <div>
        <button class="bg-primary button" @click="gotoImportBulk">
          <em class="fas fa-upload"></em><span>Import Bulk</span>
        </button>
      </div>
    </div>
    <div
      class="bg-white border border-gray-300 rounded overflow-y-hidden"
      style="height: 80%"
    >
      <div class="flex justify-between px-5 py-4" style="height: 10%">
        <div class="w-full text-primary font-semibold py-1 flex items-center">
          <div class="flex items-center">
            <span>Enrollees List</span>
          </div>
        </div>
        <div class="w-full">
          <div class="border rounded flex">
            <div class="px-4 py-2 flex items-center">
              <span class="fas fa-search" />
            </div>
            <input
              id="Email"
              @input="checkSearch"
              @blur="handleBlur"
              v-model.lazy="context.search"
              class="
                border-none
                pl-3
                py-2
                w-full
                text-sm
                focus:outline-none
                placeholder-gray-500
                rounded
                bg-transparent
                text-gray-500
                dark:text-gray-400
              "
              name="email"
              placeholder="Search enrollees, Insurance no"
              required
              type="search"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col content-between overflow-y-hidden pb-2"
        style="height: 90%"
      >
        <div class="relative flex overflow-y-auto no-scrollbar">
          <!--                    Use as overlay later-->
          <loading :active="tableBusy" :is-full-page="false" />
          <table
            aria-describedby="enrollees list"
            ref="enrolleesList"
            class="w-full whitespace-nowrap"
          >
            <RejectionReasonModal v-if="showReasonModal" @close="closeModal" :reasons="reason" :details="detailedModal"></RejectionReasonModal>

            <thead class="sticky">
              <tr
                class="w-full h-8 text-xs text-gray-300 border-b mb-5"
                tabindex="0"
              >
                <th v-for="(tableHeader, index) in fields" class="font-normal text-left" :key="index">
                  <span>{{ tableHeader.label }}</span>
                </th>
              </tr>
            </thead>
            <tbody class="w-full">
              <tr
                v-for="(data, index) in tableData"
                class="
                  h-14
                  text-sm
                  leading-none
                  text-gray-800
                  border-b border-gray-100
                "
                tabindex="{{index}}"
                :key="index"
              >
                <td v-for="(field, index) in fields" class="px-5" :key="index">
                  <span
                    v-if="!field.formatter"
                    class="text-sm font-medium leading-none text-gray-800"
                  >
                    <span
                      v-if="field.key === 'fullname'"
                      class="flex items-center space-x-4 capitalize"
                    >
                      <div class="w-10 h-10 bg-cover bg-center rounded-md">
                        <span v-if="data.photo">
                          <img
                            :src="data.photo"
                            alt=""
                            class="
                              h-full
                              w-full
                              overflow-hidden
                              object-cover
                              rounded-full
                            "
                          />
                        </span>
                        <span v-else>
                          <span
                            class="
                              h-full
                              w-full
                              rounded-full
                              flex
                              items-center
                              justify-center
                              bg-primary
                              text-white
                              font-bold
                              cursor-default
                            "
                          >
                            {{
                              getInitials(`${data.firstname} ${data.lastname}`)
                            }}
                          </span>
                        </span>
                      </div>
                      <div>{{ data.firstname }} {{ data.middle_name }} {{ data.lastname }}</div>
                    </span>
                    <span
                      v-if="field.key === 'hmo_plan_id' && data.hmo_plan_id"
                      class="capitalize"
                    >
                      {{ data.hmo_plan ? data.hmo_plan.name : "" }}
                    </span>
                    <span v-if="!['fullname', 'hmo_plan_id'].includes(field.key)">{{
                      data[field.key] ? data[field.key] : ""
                    }}</span>
                  </span>
                  <span
                    v-if="field.formatter"
                    class="text-sm font-medium leading-none text-gray-800"
                  >

                    <span v-if="field.key === 'status'">
                      <span   v-if="formatRejectedRequest(data)?.length > 0">
                        <span
                          :class="field.formatter('RE').color"
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            capitalize
                            rounded-full
                          "
                        >
                          {{ field.formatter("RE").name }}
                        </span>
                        <br>
                          <p>
                            <small
                                    @click="showModal(formatRejectedRequest(data),true)"
                                    class="
                                    cursor-pointer
                                    text-red-800
                                  "
                            >
                             Click here to see request rejected and why.
                            </small>
                          </p>
                      </span>

                        <span
                          :class="field.formatter(data[field.key]).color"
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            capitalize
                            rounded-full
                          "
                          v-else
                        >
                          {{ field.formatter(data[field.key]).name }}
                        </span>
                       <br>
                          <p>
                            <small
                                    @click="showModal(formatPendingResubmittedRequest(data),false)"
                                    class="
                                    cursor-pointer
                                    text-red-800
                                  "
                            >
<!--                              request editted and resubmitted by clients after hmo had rejected it-->
                              {{formatPendingResubmittedRequest(data)?.length > 0 ? "Click to see pending resubmitted requests" : " "}}
                            </small>
                          </p>
                      </span>
                    <span v-else>
                      {{ field.formatter(data[field.key]) }}
                    </span>
                  </span>

                  <span
                    v-if="field.key === 'action'"
                    class="text-sm font-medium leading-none text-gray-800"
                  >
                    <router-link v-if="data.status !== 'PP'"
                      :to="{ name: 'enrollee-detail', params: { id: data.id } }"
                    >
                      <em class="fas fa-eye px-2 animate"></em>
                    </router-link>
                    <router-link
                      :to="{ name: 'edit-enrollee', params: { id: data.id } }"
                      v-if="data.status === 'PP' && data.parent_id ==null"
                    >
                      <em
                        class="
                          fas
                          fa-edit
                          text-yellow-200
                          hover:text-red-700
                          cursor-pointer
                          px-2
                          animate
                        "></em>
                    </router-link>
                    <em
                      class="
                        fas
                        fa-trash
                        text-red-200
                        hover:text-red-700
                        cursor-pointer
                        px-2
                        animate
                      "
                      v-if="data.status === 'PP'"
                      @click="deleteUnconfirmedEnrollee(data.id)"
                    ></em>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex space-x-10 justify-end mt-10 text-sm px-5">
          <div>
            <span class="text-gray-400">Rows per page</span>
            <span>
              <select
                v-model="context.perPage"
                class="focus:outline-none border-none"
              >
                <option
                  v-for="(num, index) in pageOptions"
                  :value="num"
                  :key="index"
                  class="border-none"
                >
                  {{ num }}
                </option>
              </select>
            </span>
          </div>
          <div class="text-gray-400 space-x-3 cursor-pointer flex items-center">
            <span
              >{{ start === 0 ? start + 1 : start }} -
              {{
                start + context.perPage > totalRows
                  ? totalRows
                  : start + context.perPage
              }}
              {{ "of" }} {{ totalRows }}</span
            >
            <span class="inline-block space-x-5">
              <span class="fas fa-chevron-left" @click="prev"></span
              ><span class="fas fa-chevron-right" @click="next"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { getInitials } from "@/shared/helpers";
import { reactive } from "vue";
import { Axios } from "@/shared/axios";
import * as qs from "qs";
import Swal from "sweetalert2";
import { ErrorToast, SuccessToast } from "@/shared/mixins/Toast";
import RejectionReasonModal from "../../shared/components/RejectionReasonModal";

export default {
  name: "List",
  components: { Loading , RejectionReasonModal},
  mixins: [SuccessToast, ErrorToast],
  data() {
    return {
      fields: [
        { key: "fullname", label: "Name" },
        { key: "insurance_no", label: "Insurance No" },
        {
          key: "parent_id",
          label: "Type",
          formatter: (value) => {
            return value ? "Dependant" : "Principal ";
          },
        },
        { key: "hmo_plan_id", label: "Scheme" },
        {
          key: "status",
          label: "Status",
          formatter: (value) => {
            const statusKey = {
              AA: { name: "Active", color: "bg-green-300" },
              IA: { name: "Inactive", color: "bg-green-300" },
              SX: { name: "Suspended", color: "bg-green-300" },
              XX: { name: "Terminated", color: "bg-red-300" },
              PP: { name: "Pended", color: "bg-yellow-300" },
              WP: { name: "Waiting Pending", color: "bg-green-300" },
              E: { name: "E", color: "bg-green-300" },
              EX: { name: "EX", color: "bg-green-300" },
              RE: { name: "Rejected", color: "bg-red-300" },
            };
            return value ? statusKey[value] : " ";
          },
        },
        { key: "action", label: "" },
      ],
      tableData: [],
      context: reactive({
        search: '',
        currentPage: 1,
        perPage: 10,
      }),
      search: null,
      totalRows: null,
      start: null,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      tableBusy: false,
      getInitials,
      showReasonModal: false,
      reason : null,
      detailedModal : false
    };
  },
  async created() {
    // let loader = this.$loading.show();
    this.fetchEnrollees();
    // loader.hide();
  },
  methods: {
    deleteUnconfirmedEnrollee(enrolleeId) {
      Swal.fire({
        icon: "question",
        title: "Delete Enrollee",
        text: "Are you sure? This action cannot be reversed",
        showCancelButton: true,
        confirmButtonText: "<span style='color:#fff'>Yes, Delete!</span>",
        confirmButtonColor: "#9c1111",
        cancelButtonText: "No",
      }).then((result) => {
        if (!result.value) return false;

        let loader = this.$loading.show();

        Axios.delete("/enrollees/" + enrolleeId)
          .then((res) => {
            SuccessToast.fire({
              title: `<span style="color:#fff">${res.data.message}</span>`,
            });
            this.fetchEnrollees();
          })
          .catch((err) => {
            ErrorToast.fire({
              title: `<span style="color:#fff">${err.response.data.message}</span>`,
            });
          })
          .finally(() => loader.hide());
      });
    },
    gotoImportBulk() {
      this.$router.push("import-bulk-upload");
    },
    checkSearch(e) {
      if (!e.target.value) {
        this.context.search = null;
        this.refreshData();
        }
      },
    handleBlur(event) {
      event.preventDefault();
    },
    fetchEnrollees() {
      this.tableBusy = true;
      this.start =
        this.context.currentPage === 1
          ? 0
          : this.context.currentPage * this.context.perPage -
            this.context.perPage;

      let promise = Axios.get(`enrollees/DT`, {
        params: {
          DT: 1,
          length: this.context.perPage,
          start: this.start,
          search: this.context.search ? { value: this.context.search } : null,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
      });
      promise
        .then((res) => {
          this.tableData = res.data.data;
          this.totalRows = res.data.recordsTotal;
          // this.tableBusy = false;
        })
        .catch((error) => {
          console.error(error);
          // this.tableBusy = false;
        })
        .finally(() => (this.tableBusy = false));
    },
    prev: function () {
      this.context.currentPage--;
    },
    next: function () {
      this.context.currentPage++;
    },

    formatRejectedRequest(data){
      let filteredRequests = null

      if(data.requests_on?.length > 0){
        filteredRequests = data.requests_on?.filter(request_type=>request_type.status == 0)
      }
      return filteredRequests
    },

    formatPendingResubmittedRequest(data){
      let filteredRequests = null
      if(data.requests_on?.length > 0){
    filteredRequests = data.requests_on.filter(request => (request.status === null && request['reject_reason']))
      }
      return filteredRequests
    },

    showModal(reason,detailed){
      this.reason = reason
      this.detailedModal = detailed
      this.showReasonModal = true;

    },

    closeModal(){
      this.reason = null
      this.detailedModal = false
      this.showReasonModal = false;

    },
    refreshData() {
      this.$refs.enrolleesList.reload();
    },
  },
  watch: {
    context: {
      handler(val) {
        this.fetchEnrollees();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.button {
  @apply flex items-center  rounded text-white uppercase space-x-4 px-3 py-2 text-sm;
}

th {
  @apply px-5;
}

.animate {
  @apply transition duration-500 ease-in-out transform hover:scale-125;
}
</style>
