<template>
        <div class="modal">
            <div class="content">
                <h4>
                    <strong>{{details ? "Rejection Reason(s)" : "Pending Resubmitted Requests"}}</strong>
                </h4>

                <div v-for="(reason,index) in reasons" :key="index" class="m-3">
                    <h4><strong>{{formatRequestType(reason.type)}}</strong></h4>
                    <span class="m-2" v-if="details">-{{reason.reject_reason}}</span>
                    <hr>
                </div>

                <div class="text-sm text-white p-8 flex space-x-8 justify-center">
                    <button class="bg-primary button py-5" @click="closeModal">
                        <em class="fas fa-times"></em><span>Close</span>
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "RejectionReasonModal",
        props: {
            reasons : {
                type : Array,
                default : null
            },
            details : {
                type : Boolean,
                default : false
            }
        },
        methods: {
            closeModal(){
                this.$emit('close');
            },

            formatRequestType(requestType){
               return  requestType.replace(/_/g, " ").toUpperCase();
            }
        }
    }

</script>

<style scoped>
    .modal {
        position: fixed;
        top: 41vh;
        left: 5%;
        width: 90%;
        background-color: white;
        padding: 1rem;
        border-radius: 14px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 30;
        animation: slide-down 300ms ease-out forwards;
        overflow-y:hidden;
    }
    @media (min-width: 768px) {
        .modal {
            width: 33%;
            left: calc(50% - 6rem);
        }
    }

    @keyframes slide-down {
        from {
            opacity: 0;
            transform: translateY(-3rem);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .button {
        @apply flex items-center  rounded text-white uppercase space-x-4 px-3 py-2 text-sm;
    }
</style>